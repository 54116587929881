<template>
  <div>
    <div v-show="!deleteConfirmModal.isShowPopup">
      <CModal
        color="primary"
        :show.sync="showModal"
        :close-on-backdrop="false"
        size="lg"
      >
        <template #header>
          <h6 class="modal-title">{{ title }}</h6>
          <CButtonClose @click="modalCallBack(false)" class="text-black" />
        </template>
        <template #footer>
          <CButton
            class="ml-2"
            color="secondary"
            v-if="isDocsView"
            @click="modalCallBack(false)"
            >Close</CButton
          >
          <div v-else>
            <CButton
              class="ml-2"
              color="secondary"
              @click="modalCallBack(false)"
              >Cancel</CButton
            >
            <CButton
              class="ml-2"
              color="primary"
              @click="onSubmit()"
              :disabled="![4, 5].includes(activeTab)"
              >Submit</CButton
            >
          </div>
        </template>

        <LoadingOverlay v-if="isFetching" />
        <div class="m-2">
          <CTabs :activeTab="activeTab" @update:activeTab="changeActiveTab">
            <CTab :title="`Candidate Docs(${getUploadedDocument.length})`">
              <div v-if="getUploadedDocument && getUploadedDocument.length">
                <div class="table-responsive">
                  <table class="table table-striped w-100">
                    <thead>
                      <tr>
                        <th style="width: 20%">Type</th>
                        <th style="width: 40%">Name</th>
                        <th style="width: 20%">Preview</th>
                        <th style="width: 20%">Download</th>
                      </tr>
                    </thead>
                    <tbody class="text-black">
                      <tr
                        v-for="data in getUploadedDocument"
                        :key="data.candidate_document_id"
                      >
                        <td>
                          {{ data.document_type.comments || "--" }}
                        </td>
                        <td>
                          {{ data.document_name || "--" }}
                        </td>
                        <td>
                          <a
                            type="button"
                            @click="preview(data)"
                            class="p-0 hand-icon"
                          >
                            <i class="fas fa-eye ml-3"></i>
                          </a>
                        </td>
                        <td>
                          <button
                            class="action-button"
                            @click="fileDownload(data, data.document_type_id)"
                          >
                            <i class="fas fa-download ml-4"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p v-else class="text-muted h5 text-center p-4">
                Not Yet Uploaded
              </p>
            </CTab>
            <CTab :title="`Job(${_jobDocumentAction.length})`">
              <div v-if="_jobDocumentAction.length" class="table-responsive">
                <table class="table table-striped w-100">
                  <colgroup>
                    <col span="1" style="width: 40%" />
                    <col span="1" style="width: 15%" />
                    <col span="1" style="width: 15%" />
                    <col span="1" style="width: 15%" />
                    <col span="1" style="width: 15%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Response Required</th>
                      <th>File Name</th>
                      <th>Uploaded Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in _jobDocumentAction"
                      :key="index"
                    >
                      <td>
                        {{ data.type_name
                        }}<i
                          class="fa-solid fa-circle-info mx-1"
                          v-c-tooltip="{ content: data.comments }"
                        ></i>
                      </td>
                      <td>
                        {{ data.library_name || "--" }}
                      </td>
                      <td class="text-center">
                        {{ data.action_required ? "Yes" : "No" }}
                      </td>
                      <td>
                        <CButton
                          class="btn-link mb-1 cursor-pointer p-0 text-left"
                          v-if="data && data.document_name && data.link_to_doc"
                          @click="preview(data)"
                        >
                          {{ data.document_name }}
                        </CButton>
                        <p v-else>--</p>
                      </td>
                      <td>
                        {{ formatDate(data.created_on) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p v-else class="text-muted h5 text-center p-4">
                Not Yet Uploaded
              </p>
            </CTab>
            <CTab :title="`Pre-Offer(${preOfferDocuments.length})`">
              <div v-if="preOfferDocuments.length" class="table-responsive">
                <table class="table table-striped w-100">
                  <colgroup>
                    <col span="1" style="width: 30%" />
                    <col span="1" style="width: 30%" />
                    <col span="1" style="width: 20%" />
                    <col span="1" style="width: 20%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Request Type</th>
                      <th>File Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in preOfferDocuments" :key="index">
                      <td>
                        {{ data.library_name || "--" }}
                      </td>
                      <td>
                        {{ data.customer_document_type || "--" }}
                      </td>
                      <td>
                        {{ data.request_type }}
                      </td>
                      <td>
                        <CButton
                          class="btn-link mb-1 cursor-pointer p-0 text-left"
                          v-if="data && data.document_name && data.link_to_doc"
                          @click="preview(data.organisation_document)"
                        >
                          {{ data.document_name }}
                        </CButton>
                        <p v-else>--</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p v-else class="text-muted h5 text-center p-4">
                Not Yet Uploaded
              </p>
            </CTab>
            <CTab title="Interview">
              <div class="d-none" v-if="downloadFeedback">
                <InterviewFeedBack
                  :feedBackInfo="downloadFeedback"
                  ref="interviewFeedBackDownload"
                />
              </div>
              <div
                class="table-responsive"
                v-if="getAllInterviewFeedback.length"
              >
                <table class="table table-striped w-100">
                  <thead>
                    <tr>
                      <th>Interviewer</th>
                      <th>Document Name</th>
                      <th>Date Uploaded</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(feedBackInfo, index) in interviewFeedbacks"
                      :key="`feedback_${index}`"
                    >
                      <td>
                        <span>{{ feedBackInfo.interviewer }}</span>
                        <span
                          v-if="feedBackInfo.unique_url"
                          class="ml-2 cursor-pointer"
                          v-c-tooltip="{
                            content: 'Click to Copy IEF URL',
                            placement: 'left',
                            appendToBody: true,
                          }"
                          @click="
                            copyURL(`ief_url_${feedBackInfo.external_user_id}`)
                          "
                        >
                          <i class="fa-solid fa-circle-info text-primary" />
                          <input
                            type="hidden"
                            :id="`ief_url_${feedBackInfo.external_user_id}`"
                            :value="feedBackInfo.unique_url"
                          />
                        </span>
                      </td>
                      <td>
                        <div v-if="feedBackInfo.interviewFeedback">
                          <CButton
                            colour="primary"
                            class="btn-link p-0"
                            :disabled="
                              !feedBackInfo.interviewFeedback.completed
                            "
                            @click="previewIEF(feedBackInfo.interviewFeedback)"
                            >Interview Evaluation Form</CButton
                          >
                          <CButton
                            :disabled="
                              !feedBackInfo.interviewFeedback.completed
                            "
                            @click="
                              directDownloadIEF(feedBackInfo.interviewFeedback)
                            "
                            v-c-tooltip="'Click to Download File'"
                          >
                            <i class="fas fa-download ml-1 text-primary"></i>
                          </CButton>
                        </div>
                        <span v-else>--</span>
                      </td>
                      <td>
                        <div v-if="feedBackInfo.interviewFeedback">
                          {{ feedBackInfo.interviewFeedback | dateUploaded }}
                        </div>
                        <span v-else>--</span>
                      </td>
                      <td>
                        <div v-if="feedBackInfo.interviewFeedback">
                          {{
                            feedBackInfo.interviewFeedback.completed
                              ? "Completed"
                              : "In-Process"
                          }}
                        </div>
                        <span v-else>--</span>
                      </td>
                      <!-- <td>{{ feedBackInfo | dateUploaded }}</td>
                      <td>{{ feedBackInfo | uploadedBy }}</td>
                      <td>
                        {{
                          feedBackInfo.completed ? "Completed" : "In-Process"
                        }}
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <p v-else class="text-muted text-center mt-4">No Data Found</p>
              <!-- <div v-if="feedBackInfo">
                <InterviewFeedBack
                  :feedBackInfo="feedBackInfo"
                  ref="interviewFeedBack"
                />
              </div>
              <p v-else class="text-muted text-center mt-4">No Data Found</p> -->
            </CTab>

            <CTab
              :title="`Offer${
                offerDocumentActionsOffer.length
                  ? '(' + offerDocumentActionsOffer.length + ')'
                  : ''
              }`"
              :disabled="!(isOffer || isOnBoard)"
            >
              <div v-if="!isOnBoard && !isDocsView">
                <CCard>
                  <CCardBody>
                    <CRow class="mb-2">
                      <label
                        name="lbl_document_type"
                        class="col-lg-3 col-md-3 required"
                        >Document Name</label
                      >
                      <CCol md="9">
                        <CRow class="mb-2">
                          <CCol md="7">
                            <Select
                              name="document_type"
                              :options="
                                options && options['customerDocumentLib']
                                  ? options['customerDocumentLib']
                                  : []
                              "
                              :value="uploadData.document_type"
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :disabled="false"
                              @input="handleSelect"
                              :showTooltip="true"
                            />
                          </CCol>
                          <CCol md="5">
                            <div v-if="filename" class="d-flex">
                              <label class="mb-0">File Name:</label>
                              <CButton
                                class="btn-link mb-0 mx-1 p-0 cursor-pointer"
                                @click="
                                  preview(
                                    uploadData.document_type.data
                                      .organisation_document
                                  )
                                "
                              >
                                <span
                                  class="d-inline-block text-truncate"
                                  style="max-width: 100px"
                                  v-c-tooltip="{ content: filename }"
                                >
                                  {{ filename }}
                                </span>
                              </CButton>
                            </div>
                            <small v-if="description"
                              ><p>
                                <span class="text-muted">Description:</span
                                >{{ description }}
                              </p></small
                            >
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow v-if="uploadData.document_type" class="mb-2">
                      <label name="lbl_desc" class="col-md-3">Comment</label>
                      <CCol md="9">
                        <CRow>
                          <CCol md="7" class="">
                            <TextAreaInput
                              name="comments"
                              :value="uploadData.comments"
                              @input="handleInputDoc"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="3"></CCol>
                      <CCol md="7">
                        <CRow v-if="isCustomType" class="mb-2">
                          <CCol md="12">
                            <input
                              id="document-upload"
                              name="upload-document"
                              type="file"
                              ref="fileref"
                              :accept="arrayToString(fileExtention)"
                              @change="selectedFile"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="3"> </CCol>
                      <CCol md="9">
                        <CRow
                          v-if="
                            filename || uploadData.fileName || isUploadRequest
                          "
                          class="mb-2"
                        >
                          <CCol md="12">
                            <CButton
                              color="primary"
                              class="px-4 f-12"
                              @click="addToPayloadList(uploadData)"
                            >
                              ADD
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
                <div
                  class="documentScrolling document-table mt-1 table-responsive"
                  v-if="document_list.length"
                >
                  <table class="table table-striped w-100">
                    <colgroup>
                      <col span="1" style="width: 40%" />
                      <col span="1" style="width: 15%" />
                      <col span="1" style="width: 15%" />
                      <col span="1" style="width: 15%" />
                      <col span="1" style="width: 15%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Response Required</th>
                        <th>File Name</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in document_list" :key="index">
                        <td>
                          {{ data.type_name }}
                          <i
                            class="fa-solid fa-circle-info mx-1"
                            v-c-tooltip="{ content: data.comments }"
                          ></i>
                        </td>
                        <td>{{ data.library_name }}</td>

                        <td class="text-center align-middle">
                          <input
                            type="checkbox"
                            :name="`actionable-checkbox-${index}`"
                            :value="data.action_required"
                            :checked="data.action_required || false"
                            @click="handleCheck(index, $event.target.checked)"
                          />
                        </td>
                        <td>
                          <CButton
                            class="btn-link mb-1 cursor-pointer p-0 text-left"
                            v-if="data && data.link_to_doc"
                            @click="preview(data)"
                          >
                            {{ data.document_name }}
                          </CButton>
                          <p v-else-if="data && data.document_name">
                            {{ data.document_name }}
                          </p>
                          <p v-else>--</p>
                        </td>
                        <td>
                          <a
                            class="btn cursor-pointer"
                            v-if="data"
                            @click="deleteDocuments(index)"
                          >
                            <i class="icon fas fa-trash ml-1"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="offerDocumentActionsOffer.length">
                  <h6 class="text-primary">Uploaded Documents</h6>
                  <div class="table-responsive">
                    <table class="table table-striped w-100">
                      <colgroup>
                        <col span="1" style="width: 40%" />
                        <col span="1" style="width: 15%" />
                        <col span="1" style="width: 15%" />
                        <col span="1" style="width: 15%" />
                        <col span="1" style="width: 15%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Response Required</th>
                          <th>File Name</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in offerDocumentActionsOffer"
                          :key="index"
                        >
                          <td>
                            {{ data.type_name
                            }}<i
                              class="fa-solid fa-circle-info mx-1"
                              v-c-tooltip="{ content: data.comments }"
                            ></i>
                          </td>
                          <td>{{ data.library_name || "--" }}</td>
                          <td class="text-center">
                            {{ data.action_required ? "Yes" : "No" }}
                          </td>
                          <td>
                            <CButton
                              v-if="data && data.link_to_doc"
                              class="btn-link mb-1 cursor-pointer p-0 text-left"
                              @click="preview(data)"
                            >
                              {{ data.document_name }}
                            </CButton>
                            <p v-else-if="data && data.document_name">
                              {{ data.document_name }}
                            </p>
                            <p v-else>--</p>
                          </td>
                          <td>
                            <a
                              class="btn cursor-pointer"
                              v-if="data"
                              @click="deleteDocumentActionModal(data)"
                            >
                              <i class="icon fas fa-trash ml-1"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-else class="table-responsive">
                <table
                  class="table table-striped w-100"
                  v-if="offerDocumentActionsOnBoard.length"
                >
                  <thead>
                    <tr>
                      <th>Type&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th>Name</th>
                      <th>File Name</th>
                      <th>Sent Date&nbsp;&nbsp;&nbsp;</th>
                      <th>Response Required</th>
                      <th>Candidate Return</th>
                      <th>Received Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in offerDocumentActionsOnBoard"
                      :key="index"
                      :style="[
                        !isDocsView
                          ? { color: `${data.theme}` }
                          : { 'background-color': `${data.theme}` },
                      ]"
                    >
                      <td>
                        {{ data.type_name
                        }}<i
                          class="fa-solid fa-circle-info mx-1"
                          v-c-tooltip="{ content: data.comments }"
                        ></i>
                      </td>
                      <td>{{ data.library_name }}</td>
                      <td>
                        <CButton
                          class="btn-link mb-1 cursor-pointer p-0 text-left"
                          v-if="data && data.document_name && data.link_to_doc"
                          @click="preview(data)"
                        >
                          {{ data.document_name }}
                        </CButton>
                        <p v-else-if="data && data.document_name">
                          {{ data.document_name }}
                        </p>
                        <p v-else>--</p>
                      </td>
                      <td>{{ formatDate(data.sent_date) }}</td>
                      <td class="text-center">
                        {{ data.action_required ? "Yes" : "No" }}
                      </td>
                      <td>
                        <CButton
                          class="btn-link mb-1 cursor-pointer p-0 text-left"
                          v-for="returnAction in data.returnAction"
                          @click="preview(returnAction)"
                          :key="returnAction.action_id"
                        >
                          {{ returnAction.document_name }}
                        </CButton>
                        <p v-if="!data.returnAction.length">--</p>
                      </td>
                      <td>{{ formatDate(data.received_date) || "--" }}</td>
                    </tr>
                  </tbody>
                </table>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </div>
            </CTab>
            <CTab
              :title="`Onboard ${
                onboardDocumentActionsOnBoard.length
                  ? '(' + onboardDocumentActionsOnBoard.length + ')'
                  : ''
              }`"
              :disabled="!isOnBoard"
            >
              <div v-if="!isDocsView">
                <CCard>
                  <CCardBody>
                    <CRow class="mb-2">
                      <label
                        name="lbl_document_type"
                        class="col-lg-3 col-md-3 required"
                        >Document Type</label
                      >
                      <CCol md="9">
                        <CRow class="mb-2">
                          <CCol md="7">
                            <Select
                              name="document_type"
                              :options="
                                options && options['customerDocumentLib']
                                  ? options['customerDocumentLib']
                                  : []
                              "
                              :value="uploadData.document_type"
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :disabled="false"
                              @input="handleSelect"
                            />
                          </CCol>
                          <CCol md="5">
                            <div v-if="filename" class="d-flex">
                              <label class="mb-0">File Name:</label>
                              <CButton
                                class="btn-link mb-0 mx-1 p-0 cursor-pointer"
                                @click="
                                  preview(
                                    uploadData.document_type.data
                                      .organisation_document
                                  )
                                "
                              >
                                <span
                                  class="d-inline-block text-truncate"
                                  style="max-width: 100px"
                                  v-c-tooltip="{ content: filename }"
                                >
                                  {{ filename }}
                                </span>
                              </CButton>
                            </div>
                            <small v-if="description"
                              ><p>
                                <span class="text-muted">Description:</span
                                >{{ description }}
                              </p></small
                            >
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow v-if="uploadData.document_type" class="mb-2">
                      <label name="lbl_desc" class="col-md-3">Comment</label>
                      <CCol md="9">
                        <CRow>
                          <CCol md="7" class="">
                            <TextAreaInput
                              name="comments"
                              :value="uploadData.comments"
                              @input="handleInputDoc"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="3"></CCol>
                      <CCol md="6">
                        <CRow v-if="isCustomType" class="mb-2">
                          <CCol md="12">
                            <input
                              id="document-upload"
                              name="upload-document"
                              type="file"
                              ref="fileref"
                              :accept="arrayToString(fileExtention)"
                              @change="selectedFile"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="3"> </CCol>
                      <CCol md="9">
                        <CRow
                          v-if="
                            filename || uploadData.fileName || isUploadRequest
                          "
                          class="mb-2"
                        >
                          <CCol md="12">
                            <CButton
                              color="primary"
                              class="px-4 f-12"
                              @click="addToPayloadList(uploadData)"
                            >
                              ADD
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
                <div
                  class="documentScrolling document-table mt-1 table-responsive"
                  v-if="document_list.length"
                >
                  <table class="table table-striped w-100">
                    <thead>
                      <th class="w-25">Type</th>
                      <th>Name</th>
                      <th>Response Required</th>
                      <th>File Name</th>
                      <th>Delete</th>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in document_list" :key="index">
                        <td>
                          {{ data.type_name
                          }}<small
                            v-if="data.is_upload_request"
                            class="text-primary text-nowrap"
                            >(Upload Request)</small
                          ><i
                            class="fa-solid fa-circle-info mx-1"
                            v-c-tooltip="{ content: data.comments }"
                          ></i>
                        </td>
                        <td>
                          {{ data.library_name }}
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            :name="`actionable-checkbox-${index}`"
                            :value="data.action_required"
                            :checked="data.action_required || false"
                            @click="handleCheck(index, $event.target.checked)"
                            class="mb-2 ml-4"
                          />
                        </td>
                        <td>
                          <CButton
                            class="btn-link mb-1 cursor-pointer p-0 text-left"
                            v-if="data && data.link_to_doc"
                            @click="preview(data)"
                          >
                            {{ data.document_name }}
                          </CButton>
                          <p v-else-if="data && data.document_name">
                            {{ data.document_name }}
                          </p>
                          <p v-else>--</p>
                        </td>
                        <td>
                          <a
                            class="btn mb-1 cursor-pointer"
                            v-if="data"
                            @click="deleteDocuments(index)"
                          >
                            <i class="icon fas fa-trash ml-1"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="onboardDocumentActionsOnBoard.length">
                  <h6 class="text-primary">Uploaded Documents</h6>
                  <div class="table-responsive">
                    <table class="table table-striped w-100">
                      <colgroup>
                        <col span="1" style="width: 40%" />
                        <col span="1" style="width: 15%" />
                        <col span="1" style="width: 15%" />
                        <col span="1" style="width: 15%" />
                        <col span="1" style="width: 15%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Response Required</th>
                          <th>File Name</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in onboardDocumentActionsOnBoard"
                          :key="index"
                        >
                          <td>
                            {{ data.type_name
                            }}<i
                              class="fa-solid fa-circle-info mx-1"
                              v-c-tooltip="{ content: data.comments }"
                            ></i>
                          </td>
                          <td>{{ data.library_name }}</td>
                          <td class="text-center">
                            {{ data.action_required ? "Yes" : "No" }}
                          </td>
                          <td>
                            <CButton
                              v-if="data && data.link_to_doc"
                              class="btn-link mb-1 cursor-pointer p-0 text-left"
                              @click="preview(data)"
                            >
                              {{ data.document_name }}
                            </CButton>
                            <p v-else-if="data && data.document_name">
                              {{ data.document_name }}
                            </p>
                            <p v-else>--</p>
                          </td>
                          <td>
                            <a
                              class="btn cursor-pointer"
                              v-if="data"
                              @click="deleteDocumentActionModal(data)"
                            >
                              <i class="icon fas fa-trash ml-1"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-else class="table-responsive">
                <table
                  class="table table-striped w-100"
                  v-if="onboardDocumentActionsOnBoard.length"
                >
                  <thead>
                    <tr>
                      <th class="w-25">Type&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th>Name</th>
                      <th>File Name</th>
                      <th>Sent Date&nbsp;&nbsp;&nbsp;</th>
                      <th>Response Required</th>
                      <th>Candidate Return</th>
                      <th>Received Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in onboardDocumentActionsOnBoard"
                      :key="index"
                      :style="[
                        !isDocsView
                          ? { color: `${data.theme}` }
                          : { 'background-color': `${data.theme}` },
                      ]"
                    >
                      <td>
                        {{ data.type_name
                        }}<i
                          class="fa-solid fa-circle-info mx-1"
                          v-c-tooltip="{ content: data.comments }"
                        ></i>
                      </td>
                      <td>{{ data.library_name }}</td>
                      <td>
                        <CButton
                          class="btn-link mb-1 cursor-pointer text-left"
                          v-if="data && data.document_name && data.link_to_doc"
                          @click="preview(data)"
                        >
                          {{ data.document_name }}
                        </CButton>
                        <p v-else-if="data && data.document_name">
                          {{ data.document_name }}
                        </p>
                        <p v-else>--</p>
                      </td>
                      <td>{{ formatDate(data.sent_date) }}</td>
                      <td class="text-center">
                        {{ data.action_required ? "Yes" : "No" }}
                      </td>
                      <td>
                        <CButton
                          class="btn-link mb-1 cursor-pointer p-0 text-left"
                          v-for="returnAction in data.returnAction"
                          @click="preview(returnAction)"
                          :key="returnAction.action_id"
                        >
                          {{ returnAction.document_name }}
                        </CButton>
                        <p v-if="!data.returnAction.length">--</p>
                      </td>
                      <td>{{ formatDate(data.received_date) || "--" }}</td>
                    </tr>
                  </tbody>
                </table>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </div>
            </CTab>
            <CTab :title="`Licensing(0)`">
              <p class="text-muted h5 text-center p-4">Not Yet Uploaded</p>
            </CTab>
            <CTab :title="`Visa(0)`">
              <p class="text-muted h5 text-center p-4">Not Yet Uploaded</p>
            </CTab>
            <CTab :title="`Joining(0)`">
              <p class="text-muted h5 text-center p-4">Not Yet Uploaded</p>
            </CTab>
          </CTabs>
          <div v-if="!isDocsView && checkInOfferOnboardTab">
            <h6 class="border-top-primary pt-2"></h6>
            <ValidationObserver
              ref="offerOnboardForm"
              v-slot="{ handleSubmit }"
            >
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                name="offerOnboardForm"
              >
                <CRow>
                  <CCol md="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12">Candidate Joining Date</label>
                      <div class="col-lg-12">
                        <DatePicker
                          name="joining_date"
                          :value="actionData.joining_date"
                          @input="handleDateChange"
                          format="DD-MM-YYYY"
                          :showFromToday="true"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12 required">Alert Set For</label>
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="alert_days"
                            :value="actionData.alert_days"
                            :options="
                              options && options['alert_days']
                                ? options['alert_days']
                                : []
                            "
                            @change="handleChangeRadio"
                          />
                          <DatePicker
                            v-if="
                              payload.alert_days &&
                              payload.alert_days === 'custom'
                            "
                            name="expiry_date"
                            :value="actionData.expiry_date"
                            @input="handleDateChange"
                            :error="errors[0]"
                            format="DD-MM-YYYY"
                            :showFromToday="true"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6">
                    <CRow class="mb-3">
                      <label name="lbl_urgent" class="col-lg-12 col-md-12"
                        >Is Urgent?</label
                      >
                      <CCol md="12">
                        <RadioButton
                          name="is_urgent"
                          :value="actionData.is_urgent"
                          :options="
                            options && options['boolean']
                              ? options['boolean']
                              : []
                          "
                          @change="handleChangeRadio"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <!-- <CCol lg="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12 required">Priority Level</label>
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="priority_level"
                            @input="handleSelect"
                            :value="actionData.priority_level"
                            :options="
                              options && options['priority_level']
                                ? options['priority_level']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol> -->
                </CRow>
                <CRow>
                  <CCol lg="8">
                    <CRow class="mb-3">
                      <div class="col-lg-12 col-md-12 d-flex pb-2">
                        <div>
                          <label class="text-nowrap pr-3 required"
                            >Comments</label
                          >
                        </div>
                        <div
                          class="w-100"
                          v-if="options['comments_options'].length"
                        >
                          <Select
                            name="comments_options"
                            @input="handleSelect"
                            :value="actionData.comments_options"
                            :options="
                              options && options['comments_options']
                                ? options['comments_options']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextAreaInput
                            ref="comments"
                            name="comments"
                            :value="actionData.comments"
                            label="comments"
                            @input="handleInput"
                            :error="errors[0]"
                          ></TextAreaInput>
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </CModal>
    </div>

    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
      v-if="previewModal.isShowPopup"
    />

    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
      v-if="deleteConfirmModal.isShowPopup"
    />
    <CModal
      v-if="IEFModal.isShowPopup"
      color="primary"
      :show.sync="IEFModal.isShowPopup"
      :close-on-backdrop="false"
      size="xl"
    >
      <template #header>
        <h6 class="modal-title">Interview Evaluation Form</h6>
        <CButtonClose @click="IEFmodalCallBack(false)" class="text-black" />
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex py-3 mx-3">
          <div class="flex-grow-1 d-flex">
            <a @click="generatePDF" v-c-tooltip="'Click to Download File'"
              ><i class="fas fa-download ml-1 text-primary"></i
            ></a>
          </div>

          <div class="d-flex">
            <CButton
              type="button"
              variant="outline"
              color="primary"
              class="px-4"
              @click="IEFmodalCallBack(false)"
              >Close</CButton
            >
          </div>
        </div>
      </template>
      <div>
        <InterviewFeedBack
          :feedBackInfo="IEFModal.feedBackInfo"
          ref="interviewFeedBack"
          :showPrint="true"
        />
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import PreviewModal from "@/components/reusable/PreviewModal";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import pdf from "vue-pdf";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { BASE_URL } from "@/service_urls";
import CropperModal from "@/components/reusable/CropperModal";
import _ from "lodash";
import Modal from "@/components/reusable/Modal";
import InterviewFeedBack from "@/components/reusable/InterviewFeedBack.vue";
import m from "moment";

export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    buttons: {
      type: Array,
      default: () => ["Cancel", "OK"],
    },
    isOnBoard: {
      type: Boolean,
      default: () => false,
    },
    isOffer: {
      type: Boolean,
      default: () => false,
    },
    isDocsView: {
      type: Boolean,
      default: () => false,
    },
    infoData: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      default: null,
    },
    candidate: {
      type: Object,
      default: null,
    },
    job: {
      type: Object,
      default: null,
    },
  },
  components: {
    Select,
    DatePicker,
    TextInput,
    TextAreaInput,
    PreviewModal,
    CropperModal,
    Modal,
    InterviewFeedBack,
    RadioButton,
  },
  data() {
    return {
      uploadData: {},
      payload: {},
      document_list: [],
      isFetching: false,
      actionData: {},
      actionPayload: {},
      iframe: {
        url: null,
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
      },
      activeTab: 1,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      IEFModal: {
        isShowPopup: false,
        feedBackInfo: null,
      },
      downloadFeedback: null,
    };
  },
  computed: {
    ...mapGetters([
      "customerDocumentLibBasedOnType",
      "actionComments",
      "jobDocumentActions",
      "offerDocumentActions",
      "customerDocumentTypes",
      "documentExtensions",
      "onboardDocumentActions",
      "interviewFeedBack",
      "getCandidateJoiningDate",
      "getAllInterviewFeedback",
      "getCustomerId",
      "getUploadedDocument",
      "customerDocumentLibrary",
    ]),
    interviewFeedbacks() {
      const _base_domain = "https://" + window.location.host;
      return this.getAllInterviewFeedback.map((v) => ({
        interviewer: v?.user
          ? `${v?.user?.first_name} - ${v?.user?.surname}`
          : `${v?.external_user?.first_name} - ${v?.external_user?.surname}`,
        interviewFeedback: v?.interview_feedback?.length
          ? v?.interview_feedback[0]
          : null,
        external_user: v?.external_user,
        external_user_id: v?.external_user_id,
        unique_url: v?.unique_url || null,
      }));
    },
    isUploadRequest() {
      return (
        this.uploadData?.document_type?.data?.request_type ===
          "upload_request" || false
      );
    },
    _jobDocumentAction() {
      return this.jobDocumentActions.map((v) => {
        let payload = {
          created_on: v?.created_on,
        };
        payload = {
          ...payload,
          type_name: v?.document_library?.customer_document_type?.type_name,
          document_name:
            v?.document_name ||
            v?.document_library?.organisation_document?.document_name,
          action_required: v?.action_required,
          link_to_doc:
            v?.link_to_doc ||
            v?.document_library?.organisation_document?.link_to_doc,
          ext:
            v?.document_ext?.document_ext ||
            v?.document_library?.organisation_document?.document_ext
              ?.document_ext,
          comments: v?.document_library?.comments,
          library_name: v?.document_library?.library_name,
        };

        return payload;
      });
    },
    offerDocumentActionsOffer() {
      return this.offerDocumentActions
        .filter((v) => v?.actioned_by === "recruiter")
        .map((v) => {
          let payload = {
            document_action_id: v?.document_action_id,
            comments: v?.comments,
            action_required: v?.action_required,
            document_library_id: v?.document_library_id,
          };
          payload = {
            ...payload,
            type_name:
              v?.customer_document_type?.type_name ||
              v?.document_library?.customer_document_type?.type_name,
            document_name:
              v?.document_name ||
              v?.document_library?.organisation_document?.document_name,
            action_required: v?.action_required,
            link_to_doc:
              v?.link_to_doc ||
              v?.document_library?.organisation_document?.link_to_doc,
            ext:
              v?.document_ext?.document_ext ||
              v?.document_library?.organisation_document?.document_ext
                ?.document_ext,
            library_name: v?.document_library?.library_name,
          };

          return payload;
        });
    },
    offerDocumentActionsOnBoard() {
      let recruiterAction = this.offerDocumentActions
        .filter(({ actioned_by }) => actioned_by === "recruiter")
        .map((v) => {
          let payload = {
            document_action_id: v?.document_action_id,
            comments: v?.comments,
            library_name: v?.document_library?.library_name,
            sent_date: v?.created_on,
          };
          payload = {
            ...payload,
            type_name:
              v?.customer_document_type?.type_name ||
              v?.document_library?.customer_document_type?.type_name,
            document_name:
              v?.document_name ||
              v?.document_library?.organisation_document?.document_name,
            action_required: v?.action_required,
            link_to_doc:
              v?.link_to_doc ||
              v?.document_library?.organisation_document?.link_to_doc,
            data: v,
            ext:
              v?.document_ext?.document_ext ||
              v?.document_library?.organisation_document?.document_ext
                ?.document_ext,
          };
          return payload;
        });
      let candidateAction = this.offerDocumentActions
        .filter(({ actioned_by }) => actioned_by === "candidate")
        .map((v) => {
          let payload = {
            document_action_id: v?.document_action_id,
            previous_action_id: v?.previous_action_id,
            received_date: v?.created_on,
          };
          payload = {
            ...payload,
            type_name:
              v?.customer_document_type?.type_name ||
              v?.document_library?.customer_document_type?.type_name,
            document_name:
              v?.document_name ||
              v?.document_library?.organisation_document?.document_name,
            action_required: v?.action_required,
            link_to_doc:
              v?.link_to_doc ||
              v?.document_library?.organisation_document?.link_to_doc,
            data: v,
            ext:
              v?.document_ext?.document_ext ||
              v?.document_library?.organisation_document?.document_ext
                ?.document_ext,
          };
          return payload;
        });
      recruiterAction.forEach((element, index) => {
        const { document_action_id } = element;
        const returnAction =
          candidateAction.filter(
            (v) => v?.previous_action_id === document_action_id
          ) || [];
        recruiterAction[index].returnAction = returnAction;
        recruiterAction[index].received_date = returnAction[0]?.received_date;
        recruiterAction[index].theme = element.action_required
          ? returnAction.length
            ? "inherit"
            : "#ffc8c8"
          : "inherit";
      });
      return recruiterAction;
    },
    onboardDocumentActionsOnBoard() {
      let recruiterAction = this.onboardDocumentActions
        .filter(({ actioned_by }) => actioned_by === "recruiter")
        .map((v) => {
          let payload = {
            document_action_id: v?.document_action_id,
            comments: v?.comments,
            library_name: v?.document_library?.library_name,
            sent_date: v?.created_on,
          };
          payload = {
            ...payload,
            type_name:
              v?.customer_document_type?.type_name ||
              v?.document_library?.customer_document_type?.type_name,
            document_name:
              v?.document_name ||
              v?.document_library?.organisation_document?.document_name,
            action_required: v?.action_required,
            link_to_doc:
              v?.link_to_doc ||
              v?.document_library?.organisation_document?.link_to_doc,
            data: v,
            ext:
              v?.document_ext?.document_ext ||
              v?.document_library?.organisation_document?.document_ext
                ?.document_ext,
          };
          return payload;
        });
      let candidateAction = this.onboardDocumentActions
        .filter(({ actioned_by }) => actioned_by === "candidate")
        .map((v) => {
          let payload = {
            document_action_id: v?.document_action_id,
            previous_action_id: v?.previous_action_id,
            received_date: v?.created_on,
          };
          payload = {
            ...payload,
            type_name:
              v?.customer_document_type?.type_name ||
              v?.document_library?.customer_document_type?.type_name,
            document_name:
              v?.document_name ||
              v?.document_library?.organisation_document?.document_name,
            action_required: v?.action_required,
            link_to_doc:
              v?.link_to_doc ||
              v?.document_library?.organisation_document?.link_to_doc,
            data: v,
            ext:
              v?.document_ext?.document_ext ||
              v?.document_library?.organisation_document?.document_ext
                ?.document_ext,
          };
          return payload;
        });
      recruiterAction.forEach((element, index) => {
        const { document_action_id } = element;
        const returnAction =
          candidateAction.filter(
            (v) => v?.previous_action_id === document_action_id
          ) || [];
        recruiterAction[index].returnAction = returnAction;
        recruiterAction[index].received_date = returnAction[0]?.received_date;
        recruiterAction[index].theme = element.action_required
          ? returnAction
            ? "inherit"
            : "#ffc8c8"
          : "inherit";
      });
      return recruiterAction;
    },
    isCustomType() {
      if (this.uploadData?.document_type)
        return (
          this.uploadData?.document_type?.data?.request_type === "custom" ||
          false
        );
      return false;
    },
    description() {
      return (
        this.uploadData?.document_type?.data?.description ||
        this.uploadData?.document_type?.data?.document_library
          ?.document_description
      );
    },
    showModal() {
      return (
        this.isShowPopup &&
        !this.previewModal.isShowPopup &&
        !this.IEFModal.isShowPopup
      );
    },
    filename() {
      if (this.uploadData?.document_type)
        return this.uploadData?.document_type?.data?.organisation_document
          ?.document_name;
      return null;
    },
    options() {
      return {
        customerDocumentLib: this.typeBasedDocumentType || [],
        priority_level: [
          { code: 1, label: "High" },
          { code: 2, label: "Medium" },
          { code: 3, label: "Low" },
        ],
        comments_options: this.actionComments || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        alert_days: [
          { code: 1, label: "1 Day" },
          { code: 3, label: "3 Days" },
          { code: 7, label: "7 Days" },
          { code: "custom", label: "Customize" },
        ],
      };
    },
    typeBasedDocumentType() {
      const category = this.isOnBoard ? ["licensing"] : ["offer"];
      const candidate_type_id =
        this.infoData?.candidate?.candidate_type_id ||
        this.candidate?.candidate_type_id;
      return this.customerDocumentLibBasedOnType({
        candidate_type_id,
        category,
      });
    },
    preOfferDocuments() {
      const category = "pre_offer";
      const candidate_type_id =
        this.infoData?.candidate?.candidate_type_id ||
        this.candidate?.candidate_type_id;
      return this.customerDocumentLibrary
        .filter(
          (v) =>
            v?.candidate_type_ids.includes(candidate_type_id) &&
            v?.category === category
        )
        .map((v) => ({
          library_name: v?.library_name || "--",
          customer_document_type: v?.customer_document_type?.type_name || "--",
          decription: v?.comments || "--",
          request_type: _.startCase(v?.request_type) || "--",
          document_name: v?.organisation_document?.document_name,
          link_to_doc: v?.organisation_document?.link_to_doc,
          organisation_document_id: v?.organisation_document_id,
          organisation_document: v?.organisation_document,
        }));
    },
    fileExtention() {
      return (
        this.documentExtensions.map(({ label }) => `.${label.toLowerCase()}`) ||
        []
      );
    },
    checkInOfferOnboardTab() {
      let tabs = this.isOffer ? 4 : this.isOnBoard ? 5 : null;
      return !this.isDocsView && this.activeTab === tabs;
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomerDocumentLib",
      "fetchCommentsfor_recruiter",
      "showToast",
      "getJobDocumentAction",
      "initUpdateJobStatus",
      "getOfferDocumentAction",
      "fetchCustomerDocumentTypes",
      "fetchDocumentExtension",
      "getOnBoardDocumentAction",
      "deleteDocumentAction",
      "fetchInterviewFeedBack",
      "fetchCandidateJoiningDate",
      "fetchAllInterviewFeedback",
      "fetchUploadedDocumentbyCandidateId",
      "downloadDocument",
    ]),
    previewIEF(data) {
      this.IEFModal.feedBackInfo = data;
      this.IEFModal.isShowPopup = true;
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    arrayToString(data) {
      return data.join();
    },
    formatDate(data) {
      if (data) return moment(data).format("DD MMM YYYY");
      return "--";
    },
    addToPayloadList(item) {
      const {
        document_type: { data },
      } = item;
      const isAlreadyExist = data?.document_library_id
        ? this.document_list.some(
            ({ document_library_id }) =>
              document_library_id === data?.document_library_id &&
              data?.request_type != "custom"
          ) ||
          this.offerDocumentActionsOffer.some(
            ({ document_library_id }) =>
              document_library_id === data?.document_library_id &&
              data?.request_type != "custom"
          ) ||
          this.onboardDocumentActionsOnBoard.some(
            ({ data: { document_library_id } }) =>
              document_library_id === data?.document_library_id &&
              data?.request_type != "custom"
          )
        : false;
      if (isAlreadyExist) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Document Already Exist.",
          autohide: 2000,
        });
        this.uploadData = {};
        return;
      }
      const { comments } = this.uploadData;
      let payload = {
        comments,
        type_name: data?.customer_document_type?.type_name,
        customer_document_type_id: data?.customer_document_type_id,
        library_name: data?.library_name,
        document_library_id: data?.document_library_id,
      };
      if (data?.request_type != "custom") {
        const document_library = data;
        payload = {
          ...payload,
          document_name: document_library?.organisation_document?.document_name,
          action_required: document_library?.sign_req,
          link_to_doc: document_library?.organisation_document?.link_to_doc,
          ext: document_library?.organisation_document?.document_ext
            ?.document_ext,
          is_upload_request:
            document_library?.request_type === "Upload Request" || false,
        };
      } else {
        payload = {
          ...payload,
          action_required: false,
          document_name: item?.fileName,
          file: item?.file,
        };
      }
      this.document_list = [...this.document_list, payload];
      this.uploadData = {};
    },
    handleInputDoc(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value,
      };
    },
    handleSelect(name, value) {
      if (name === "document_type") {
        Vue.set(this.uploadData, name, value);
        const desc = this.uploadData?.document_type?.data?.comments;
        this.handleInputDoc("comments", desc);
      } else {
        Vue.set(this.actionData, name, value);
        this.actionPayload = {
          ...this.actionPayload,
          [name]: value ? value.id || value.code || value : null,
        };
        if (name === "comments_options")
          this.handleInput("comments", value.label);
      }
      this.payload = {
        ...this.payload,
        [name]: value ? value?.id || value?.code || value : null,
      };
    },
    handleCheck(index, value) {
      let v = null;
      if (value && (value.id === false || value.code === false)) {
        v = false;
      } else {
        v = value?.id || value?.code || value;
      }
      Vue.set(this.document_list[index], "action_required", v);
    },
    fileDownload(data, docId) {
      let { link_to_doc, document_name } = data;
      let url = link_to_doc.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
    },
    preview(data) {
      var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      var _base_domain = "https://" + window.location.host;
      const ext = data?.ext || data?.document_ext?.document_ext;
      this.iframe.url =
        _baseUrl.substring(0, _baseUrl.indexOf("/api")) + data.link_to_doc;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link_to_doc;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          _base_domain + data.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(ext)) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link_to_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    deleteDocuments(index) {
      return this.document_list.splice(index, 1);
    },
    handleInput(name, value) {
      Vue.set(this.actionData, name, value);
      Vue.set(this.actionPayload, name, value);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.actionData, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (name === "alert_days" && val != "custom") {
        this.handleDateChange("expiry_date", m().add(val, "d").toDate());
      }
    },
    handleDateChange(name, value) {
      Vue.set(this.actionData, name, value);
      Vue.set(
        this.actionPayload,
        name,
        value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    setDefaultAlertDate() {
      let alertDate = moment().add(7, "d").toDate();
      this.handleDateChange("expiry_date", alertDate);
      // this.handleSelect("priority_level", { code: 2, label: "Medium" });
      this.handleChangeRadio("is_urgent", false);
      this.handleChangeRadio("alert_days", 7);
      if (!this.isDocsView && this.getCandidateJoiningDate?.joining_date) {
        this.actionData["joining_date"] = moment(
          this.getCandidateJoiningDate?.joining_date
        ).toDate();
      }
      return true;
    },
    async onSubmit() {
      const isValid = await this.$refs.offerOnboardForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
        return;
      }
      const isOneActionable = this.document_list.some(
        ({ action_required }) => action_required
      );
      const isOneActionableOldOffer =
        !this.isOnBoard && this.offerDocumentActionsOffer.length
          ? this.offerDocumentActionsOffer.some(
              ({ action_required }) => action_required
            )
          : false;
      const isOneActionableOldOnBoard =
        this.isOnBoard && this.onboardDocumentActionsOnBoard.length
          ? this.onboardDocumentActionsOnBoard.some(
              ({ action_required }) => action_required
            )
          : false;
      if (!this.document_list.length) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Add atleast one document!",
          autohide: 2000,
        });
        return;
      }
      if (
        !(
          isOneActionable ||
          (this.isOffer ? isOneActionableOldOffer : isOneActionableOldOnBoard)
        )
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select atleast any one actionable document!",
          autohide: 2000,
        });
        return;
      }
      const {
        status_id,
        sub_status_id,
        candidate_uid,
        job_id,
        organisation_id,
      } = this.infoData;
      let payload = {};
      const isFromDashBoard = this.action ? false : true;
      if (!this.isOnBoard) {
        payload = {
          sub_status_id: 59,
          action_id: 7,
          filter_id: 28,
          tab_id: 4,
          current_status: status_id,
          current_sub_status: sub_status_id,
          expiry_date: this.actionPayload?.expiry_date,
          // priority_level: this.actionPayload?.priority_level,
          comments: this.actionPayload?.comments,
          isFromDashBoard,
        };
      } else {
        payload = {
          sub_status_id: 69,
          action_id: 8,
          filter_id: 29,
          tab_id: 4,
          current_status: status_id,
          current_sub_status: sub_status_id,
          expiry_date: this.actionPayload?.expiry_date,
          // priority_level: this.actionPayload?.priority_level,
          comments: this.actionPayload?.comments,
          isFromDashBoard,
        };
      }
      const documentPayload =
        this.document_list.map((v) => {
          let payload = {
            job_id,
            candidate_uid,
            action_type: this.isOnBoard ? "licensing" : "offer",
            actioned_by: "recruiter",
            action_required: v?.action_required,
            comments: v?.comments,
            organisation_id,
          };
          if (v?.document_library_id)
            payload = {
              ...payload,
              document_library_id: v?.document_library_id,
            };
          if (v?.customer_document_type_id && v?.file)
            payload = {
              ...payload,
              customer_document_type_id: v?.customer_document_type_id,
              file: v?.file,
              fileName: v?.document_name,
            };
          return payload;
        }) || [];
      const processPayload = { candidate_uid, job_id, payload };
      this.modalCallBack(true, {
        documentPayload,
        processPayload,
      });
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
      if (this.action && action) {
        const { action_id, filter_id, tab_id } = this.action;
        const { status_id: current_status, sub_status_id: current_sub_status } =
          this.infoData;
        const actionPayload = {
          action_id,
          filter_id,
          tab_id,
          current_status,
          current_sub_status,
        };
        this.$emit("actionCallBack", action, { ...payload, actionPayload });
      } else {
        this.$emit("actionCallBack", action, payload);
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return `.${parts[parts.length - 1]}`;
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        if (this.fileExtention.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.fileData = file;
            this.cropperModal.isShowPopup = true;
          } else {
            this.uploadData = {
              ...this.uploadData,
              file,
              fileName: file?.name,
            };
          }
        } else {
          let fileTypeError = this.arrayToString(this.fileExtention);
          this.$refs.fileref.value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
          return;
        }
      } else {
        this.$refs.fileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.uploadData = { ...this.uploadData, fileName };
        this.uploadData.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    deleteDocumentActionModal(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${
        data?.type_name
      }${data?.library_name ? " | " + data.library_name : ""} ?`;
      this.deleteConfirmModal.data = data.document_action_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        const document_action_id = this.deleteConfirmModal.data;
        this.deleteDocumentAction({ document_action_id }).then(async (res) => {
          const { job_id, candidate_uid, organisation_id } = this.infoData;
          if (!this.isOnBoard) {
            const offerpayload = {
              job_id,
              action_type: "offer",
              candidate_uid,
              all_rows: true,
              organisation_id,
            };
            await this.getOfferDocumentAction(offerpayload);
          }
          if (this.isOnBoard) {
            await this.getOnBoardDocumentAction({
              job_id,
              action_type: "licensing",
              candidate_uid,
              all_rows: true,
              organisation_id,
            });
          }
          this.deleteConfirmModal.isShowPopup = false;
          this.deleteConfirmModal.data = null;
        });
      } else {
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.data = null;
      }
    },
    generatePDF() {
      this.$refs.interviewFeedBack.generateReport();
    },
    directDownloadIEF(data) {
      this.downloadFeedback = data;
      setTimeout(() => {
        this.$refs?.interviewFeedBackDownload?.generateReport();
      }, 100);
    },
    IEFmodalCallBack() {
      this.IEFModal.isShowPopup = false;
      this.IEFModal.feedBackInfo = null;
    },
    copyURL(id) {
      let testingCodeToCopy = document.getElementById(id);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        this.showToast({
          message: `URL copied ${msg}`,
          class: `text-white ${
            msg === "successful" ? "bg-success" : "bg-danger"
          }`,
        });
      } catch (err) {
        this.showToast({
          message: `Unable to Copy`,
          class: `text-white bg-danger`,
        });
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
  mounted() {
    this.activeTab = this.isOnBoard ? 5 : this.isOffer ? 4 : 0;
    const {
      status_id,
      sub_status_id,
      job_id,
      candidate_uid,
      action_detail_id,
      organisation_id,
    } = this.infoData;
    let appendAction = [];
    const queryPayload = {
      // action_detail_ids__overlap: `{${action_detail_id}}`,
      candidate_uid,
      job_id,
      order_by: "-feedback_id",
      limit: 1,
    };
    // appendAction = [...appendAction, this.fetchInterviewFeedBack(queryPayload)];
    appendAction = [
      ...appendAction,
      this.fetchAllInterviewFeedback({ job_id, candidate_uid }),
    ];
    appendAction = [...appendAction, this.fetchCustomerDocumentLib()];
    appendAction = [
      ...appendAction,
      this.fetchCommentsfor_recruiter({ status_id, sub_status_id }),
    ];
    let payload = {
      job_id,
      action_type: "job",
      candidate_uid__isnull: true,
      all_rows: true,
      organisation_id,
    };
    appendAction = [...appendAction, this.getJobDocumentAction(payload)];
    const documentPayload = {
      customer_uid: this.getCustomerId,
      candidate_uid,
    };
    appendAction = [
      ...appendAction,
      this.fetchUploadedDocumentbyCandidateId(documentPayload),
    ];
    const offerpayload = {
      job_id,
      action_type: "offer",
      candidate_uid,
      all_rows: true,
      organisation_id,
    };
    appendAction = [...appendAction, this.getOfferDocumentAction(offerpayload)];
    if (!this.documentExtensions.length)
      appendAction = [...appendAction, this.fetchDocumentExtension()];
    if (this.isOnBoard) {
      appendAction = [
        ...appendAction,
        this.getOnBoardDocumentAction({
          job_id,
          action_type: "licensing",
          candidate_uid,
          all_rows: true,
          organisation_id,
        }),
      ];
    }
    if (!this.isDocsView)
      appendAction = [
        ...appendAction,
        this.fetchCandidateJoiningDate({
          order_by: "-action_detail_id",
          date_field: "initiated_date",
          completed_by__isnull: true,
          skip: 0,
          limit: 1,
          action_id: 18,
          job_id,
          candidate_uid,
        }),
      ];
    this.isFetching = true;
    Promise.all(appendAction).then(async (res) => {
      this.isFetching = false;
      await this.setDefaultAlertDate();
    });
  },
  filters: {
    removeExtension(data) {
      if (data) return data.split(".").slice(0, -1).join(".");
      return "--";
    },
    dateUploaded(data) {
      if (data?.last_modified_on)
        return m
          .utc(data?.last_modified_on)
          .local()
          .format("DD-MMM-YYYY hh:mm A");
      else if (data?.created_on)
        return m.utc(data?.created_on).local().format("DD-MMM-YYYY hh:mm A");
    },
    uploadedBy(data) {
      if (data?.last_modified_by_user)
        return `${data.last_modified_by_user.first_name} ${data.last_modified_by_user.surname}`;
      return `${data.created_by_user.first_name} ${data.created_by_user.surname}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-danger td {
  color: #e55353 !important;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  color: #3c4b64;
  cursor: pointer;
}
</style>